<template>
  <div class="container-fluid">
    <package-form
      :loading="loading"
      :packData="pack"
      :formErrors="formErrors"
      @packSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
      @onClosePackageModal="onClosePackageModal"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import defaultTaxes from "@/constants/defaultTaxes";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultPackage from "../defaultPackage";
import PackageForm from "../partials/PackageForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    PackageForm,
  },

  mixins: [alertLeave],

  data() {
    const pack = cloneDeep(defaultPackage);
    pack.taxes = cloneDeep(defaultTaxes);
    return {
      pack: pack,
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(pack) {
      this.loading = true;

      const packData = cloneDeep(pack);
      delete packData.id;

      try {
        await this.$store.dispatch("packages/add", packData);
        this.$notify({
          type: "success",
          message: this.$t("PACKAGES.PACKAGE_ADDED"),
        });
        const pack = await this.$store.getters["packages/package"];
        this.$emit("onViewPackage", pack, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    onClosePackageModal() {
      this.$emit("onClosePackageModal");
    },
  },
};
</script>
