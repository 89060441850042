<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-3">{{ $t("USERS.AUTHORIZATIONS") }}</h3>
    <dl class="row">
      <h3>
        <router-link :to="$objectViewRoute(pack.default_role)">
          {{ pack.default_role.name }}
        </router-link>
      </h3>
      <role-permissions :role="pack.default_role" />
    </dl>
  </div>
</template>
<script>
import RolePermissions from "@/components/RolePermissions.vue";

export default {
  name: "package-view-authorizations",

  components: { RolePermissions },

  props: ["pack"],

  mixins: [],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {
    user(user) {},
  },
};
</script>
