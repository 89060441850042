var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",staticClass:"add-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('div',{staticClass:"add-form-inner"},[_c('div',{staticClass:"form-wrapper"},[_c('image-selector',{attrs:{"label":_vm.$t('COMMON.PICTURE'),"defaultImage":_vm.pack.picture,"ressource_name":"packages","ressource_id":_vm.pack.id ? _vm.pack.id : 0,"field":"picture"},on:{"imageChanged":(file_url) => {
            _vm.pack.picture = file_url;
            _vm.onFormChanged();
          }}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.attachment}})],1),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_RESELLERS))?_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.RESELLER')} (*)`,"placeholder":_vm.$t('COMMON.RESELLER')}},[_c('reseller-selector',{attrs:{"reseller":_vm.pack.reseller.id,"filterable":true,"showAll":false},on:{"resellerChanged":_vm.resellerChanged}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.reseller}})],1):_vm._e(),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.NAME')} (*)`,"placeholder":_vm.$t('COMMON.NAME')},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.pack.name),callback:function ($$v) {_vm.$set(_vm.pack, "name", $$v)},expression:"pack.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.name}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"type":"textarea","label":_vm.$t('COMMON.EXCERPT'),"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.pack.excerpt),callback:function ($$v) {_vm.$set(_vm.pack, "excerpt", $$v)},expression:"pack.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.PRICE')} (*)`,"placeholder":_vm.$t('COMMON.PRICE')},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.pack.price),callback:function ($$v) {_vm.$set(_vm.pack, "price", $$v)},expression:"pack.price"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.price}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('PACKAGES.FREQUENCY')} (*)`,"placeholder":_vm.$t('PACKAGES.FREQUENCY')},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.pack.frequency),callback:function ($$v) {_vm.$set(_vm.pack, "frequency", $$v)},expression:"pack.frequency"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.frequency}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('PACKAGES.MAXIMUM_USERS')} (*)`,"placeholder":_vm.$t('PACKAGES.MAXIMUM_USERS')},on:{"change":() => {
            _vm.onFormChanged();
          }},model:{value:(_vm.pack.maximum_users),callback:function ($$v) {_vm.$set(_vm.pack, "maximum_users", $$v)},expression:"pack.maximum_users"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.maximum_users}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('PACKAGES.DEFAULT_ROLE')} (*)`,"placeholder":_vm.$t('PACKAGES.DEFAULT_ROLE')}},[_c('role-selector',{attrs:{"disabled":!_vm.pack.reseller.id,"role":_vm.pack.default_role.id,"filterable":true,"showAll":false,"filterReseller":_vm.pack.reseller.id},on:{"roleChanged":(roleId) => {
              _vm.pack.default_role.id = roleId;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.default_role}})],1)]),_c('div',{staticClass:"submit-wrapper"},[_c('base-button',{staticClass:"btn cancel",attrs:{"type":"button"},on:{"click":_vm.onClosePackageModal}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.CANCEL"))+" ")]),_c('base-button',{staticClass:"btn btn-sm kw-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.pack.id ? _vm.$t("COMMON.EDIT") : _vm.$t("COMMON.ADD_ITEM"))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }